<template>
  <transition name="fade">
    <div class="content-fade" v-if="fadeMenu">
      <div class="container-menu-left">
        <!-- logo section -->
        <div class="content-image">
          <img @click="home" style="cursor: pointer;" src="@/assets/logos/logo-bg-black.svg" alt="" />
        </div>
        <!-- options menu section -->
        <div class="container-options-menu" :style="{ height: menuHeight + 'px' }">
          <v-list class="content-options-menu" color="transparent">
            <!-- home -->
            <v-list-item
              @click="$store.commit('setFadeMenu', false)"
              to="/home"
              exact
              :style="this.$route.name == 'Home' ? { backgroundColor: '#283C4D', borderRadius: '13px' } : {}"
            >
              <div :class="this.$route.name == 'Home' ? 'active-menu' : ''" />
              <v-list-item-icon>
                <v-icon color="#ffffff" size="18px">
                  mdi-home-variant-outline
                </v-icon>
              </v-list-item-icon>

              <v-list-item-title class="mon-medium">{{ texts.menu.titleHome }}</v-list-item-title>
            </v-list-item>
            <!-- <v-list-item
              @click="$store.commit('setFadeMenu', false)"
              to="/about"
              exact
              :style="this.$route.name == 'About' ? { backgroundColor: '#283C4D', borderRadius: '13px' } : {}"
            >
              <div :class="this.$route.name == 'About' ? 'active-menu' : ''" />
              <v-list-item-icon>
                <v-icon color="#ffffff" size="18px">
                  mdi-account-group
                </v-icon>
              </v-list-item-icon>

              <v-list-item-title class="mon-medium">{{ texts.menu.titleAbout }}</v-list-item-title>
            </v-list-item> -->
            <!-- catalogos -->
            <v-list-group style="position: relative;" v-model="activeCatalogGroup">
              <div :class="this.$route.name == 'Catalog' ? 'active-menu-1' : ''" />
              <div :class="this.$route.name == 'Subcatalog' ? 'active-menu-2' : ''" />
              <div :class="this.$route.name == 'Article' ? 'active-menu-3' : ''" />
              <template v-slot:activator>
                <v-list-item-icon>
                  <v-icon color="#ffffff" size="18px">
                    mdi-folder-outline
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title class="mon-medium">{{ texts.menu.titleCatalog }}</v-list-item-title>
              </template>
              <v-list-item
                @click="$store.commit('setFadeMenu', false)"
                :to="{ name: 'Catalog' }"
                exact
                :style="this.$route.name == 'Catalog' ? { backgroundColor: '#283C4D', borderRadius: '13px' } : {}"
                class="mt-2 mon-regular"
              >
                <v-list-item-title>{{ texts.menu.catalog.textCatalog }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="$store.commit('setFadeMenu', false)"
                :to="{ name: 'Subcatalog', params: { id: 'all-b87bbcf26818fb2e6262fffeab402f03' } }"
                exact
                :style="this.$route.name == 'Subcatalog' ? { backgroundColor: '#283C4D', borderRadius: '13px' } : {}"
                class="mt-2 mon-regular"
              >
                <v-list-item-title>{{ texts.menu.catalog.textSubcatalog }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="$store.commit('setFadeMenu', false)"
                :to="{
                  name: 'Article',
                  params: { idCategory: 'all', idSubcategory: 'b87bbcf26818fb2e6262fffeab402f03' },
                }"
                exact
                :style="this.$route.name == 'Article' ? { backgroundColor: '#283C4D', borderRadius: '13px' } : {}"
                class="mt-2 mon-regular"
              >
                <v-list-item-title>{{ texts.menu.catalog.textArticle }}</v-list-item-title>
              </v-list-item>
            </v-list-group>

            <!-- almacenes -->
            <v-list-item @click="$store.commit('setFadeMenu', false)" v-if="isLogged" to="/warehouse">
              <div
                :class="this.$route.name == 'Warehouse' || this.$route.name == 'AddWarehouse' ? 'active-menu' : ''"
              />
              <v-list-item-icon>
                <v-icon color="#ffffff" size="18px">
                  mdi-archive-outline
                </v-icon>
              </v-list-item-icon>

              <v-list-item-title class="mon-medium">{{ texts.menu.titleWarehouse }}</v-list-item-title>
            </v-list-item>

            <!-- Proveedores -->
            <v-list-item
              @click="$store.commit('setFadeMenu', false)"
              to="/vendor"
              exact
              :style="this.$route.name == 'Vendor' ? { backgroundColor: '#283C4D', borderRadius: '13px' } : {}"
            >
              <div :class="this.$route.name == 'Vendor' ? 'active-menu' : ''" />
              <v-list-item-icon>
                <v-icon color="#ffffff" size="18px">
                  mdi-account-outline
                </v-icon>
              </v-list-item-icon>

              <v-list-item-title class="mon-medium">{{ texts.menu.titleProvider }}</v-list-item-title>
            </v-list-item>

            <!-- Pedidos -->
            <v-list-group v-if="isLogged" style="position: relative;" v-model="activeOrderGroup">
              <div :class="this.$route.path == '/order/tab/1' ? 'active-menu-8' : ''" />
              <div :class="this.$route.path == '/order/tab/2' ? 'active-menu-9' : ''" />
              <div :class="this.$route.path == '/order/tab/3' ? 'active-menu-10' : ''" />

              <template v-slot:activator>
                <v-list-item-icon>
                  <v-icon color="#ffffff" size="18px">
                    mdi-bookmark-outline
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title class="mon-medium">{{ texts.menu.titleOrder }}</v-list-item-title>
              </template>
              <v-list-item
                @click="$store.commit('setFadeMenu', false)"
                :to="{ path: '/order/tab/1', params: { num: '1' } }"
                exact
                :style="this.$route.path == '/order/tab/1' ? { backgroundColor: '#283C4D', borderRadius: '13px' } : {}"
                class="mt-2 mon-regular"
              >
                <v-list-item-title>
                  {{ texts.menu.order.textInProcess }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="$store.commit('setFadeMenu', false)"
                :to="{ path: '/order/tab/2', params: { num: '2' } }"
                exact
                :style="this.$route.path == '/order/tab/2' ? { backgroundColor: '#283C4D', borderRadius: '13px' } : {}"
                class="mt-2 mon-regular"
              >
                <v-list-item-title>
                  {{ texts.menu.order.textFinalized }}
                </v-list-item-title>
              </v-list-item>
            </v-list-group>

            <!-- Historial de disputas -->
            <v-list-group v-if="isLogged" style="position: relative;" v-model="activeDisputesGroup">
              <div :class="this.$route.path == '/disputes/tab/1' ? 'active-menu-11' : ''" />
              <div :class="this.$route.path == '/disputes/tab/2' ? 'active-menu-12' : ''" />

              <template v-slot:activator>
                <v-list-item-icon>
                  <v-icon color="#ffffff" size="18px">
                    mdi-alert-outline
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title class="mon-medium">{{ texts.menu.titleDisputeHistory }}</v-list-item-title>
              </template>
              <v-list-item
                @click="$store.commit('setFadeMenu', false)"
                :to="{ path: '/disputes/tab/1', params: { num: '1' } }"
                exact
                :style="
                  this.$route.path == '/disputes/tab/1' ? { backgroundColor: '#283C4D', borderRadius: '13px' } : {}
                "
                class="mt-2 mon-regular"
              >
                <v-list-item-title>
                  {{ texts.menu.disputeHistory.textInProcess }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="$store.commit('setFadeMenu', false)"
                :to="{ path: '/disputes/tab/2', params: { num: '2' } }"
                exact
                :style="
                  this.$route.path == '/disputes/tab/2' ? { backgroundColor: '#283C4D', borderRadius: '13px' } : {}
                "
                class="mt-2 mon-regular"
              >
                <v-list-item-title>
                  {{ texts.menu.disputeHistory.textFinalized }}
                </v-list-item-title>
              </v-list-item>
            </v-list-group>

            <!-- Calculadora Proplat -->
            <!-- <v-list-item
              @click="$store.commit('setFadeMenu', false)"
              to="/proplatcalculator"
              exact
              :style="this.$route.name == 'ProplatCalculator' ? { backgroundColor: '#283C4D', borderRadius: '13px' } : {}"
            >
              <div :class="this.$route.name == 'ProplatCalculator' ? 'active-menu' : ''" />
              <v-list-item-icon>
                <v-icon color="#ffffff" size="18px">
                  mdi-calculator
                </v-icon>
              </v-list-item-icon>

              <v-list-item-title class="mon-medium">{{ texts.menu.titleProplatCalculator }}</v-list-item-title>
            </v-list-item> -->
            <!-- Perfil de usuario -->
            <v-list-group v-if="isLogged" style="position: relative;" v-model="activeProfileGroup">
              <div :class="this.$route.path == '/profile/tab/1' ? 'active-menu-4' : ''" />
              <div :class="this.$route.path == '/profile/tab/2' ? 'active-menu-5' : ''" />
              <div :class="this.$route.path == '/profile/tab/3' ? 'active-menu-6' : ''" />
              <div
                v-if="this.$store.state.isManager"
                :class="this.$route.path == '/profile/tab/4' ? 'active-menu-7' : ''"
              />

              <template v-slot:activator>
                <v-list-item-icon>
                  <v-icon color="#ffffff" size="18px">
                    mdi-shopping-outline
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title class="mon-medium">{{ texts.menu.titleUserProfile }}</v-list-item-title>
              </template>
              <v-list-item
                @click="$store.commit('setFadeMenu', false)"
                :to="{ path: '/profile/tab/1', params: { num: '1' } }"
                exact
                :style="
                  this.$route.path == '/profile/tab/1' ? { backgroundColor: '#283C4D', borderRadius: '13px' } : {}
                "
                class="mt-2 mon-regular"
              >
                <v-list-item-title>{{ texts.menu.userProfile.textUserData }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="$store.commit('setFadeMenu', false)"
                :to="{ path: '/profile/tab/2', params: { num: '2' } }"
                exact
                :style="
                  this.$route.path == '/profile/tab/2' ? { backgroundColor: '#283C4D', borderRadius: '13px' } : {}
                "
                class="mt-2 mon-regular"
              >
                <v-list-item-title>{{ texts.menu.userProfile.textEnterpriseData }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="$store.commit('setFadeMenu', false)"
                :to="{ path: '/profile/tab/3', params: { num: '3' } }"
                exact
                :style="
                  this.$route.path == '/profile/tab/3' ? { backgroundColor: '#283C4D', borderRadius: '13px' } : {}
                "
                class="mt-2 mon-regular"
              >
                <v-list-item-title>{{ texts.menu.userProfile.textAttachment }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="this.$store.state.isManager"
                @click="$store.commit('setFadeMenu', false)"
                :to="{ path: '/profile/tab/4', params: { num: '4' } }"
                exact
                :style="
                  this.$route.path == '/profile/tab/4' ? { backgroundColor: '#283C4D', borderRadius: '13px' } : {}
                "
                class="mt-2 mon-regular"
              >
                <v-list-item-title>{{ texts.menu.userProfile.textBuyer }}</v-list-item-title>
              </v-list-item>
            </v-list-group>
          </v-list>
        </div>
        <!-- language selection -->
        <div class="container-languages pa-9">
          <language-component></language-component>
        </div>
        <!-- close sesion -->
        <div class="">
          <v-btn v-if="isLogged" class="button-session mon-regular" @click="logout">
            <v-icon class="mr-2">
              mdi-logout
            </v-icon>
            {{ texts.menu.buttonLogout }}
          </v-btn>
          <v-btn v-else class="button-session mon-regular" @click="login">
            <v-icon class="mr-2">
              mdi-login
            </v-icon>
            {{ texts.menu.buttonLogin }}
          </v-btn>
        </div>
      </div>
      <div class="container-menu-right" @click="closeFadeMenu"></div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "MenuComponent",
  data() {
    return {
      //VARIABLES
      texts: "",
      menuHeight: 0,
      activeCatalogGroup: false,
      activeProfileGroup: false,
      activeOrderGroup: false,
      activeDisputesGroup: false,
    };
  },
  beforeMount() {
    this.texts = FILE.menu[this.selectLanguage];
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function() {
      this.menuHeight = window.innerHeight - 305;
    },
    closeFadeMenu: function() {
      this.$store.commit("setFadeMenu", false);
    },
    home: function() {
      this.$router.push("/home").catch(() => {});
      this.$store.commit("setFadeMenu", false);
    },
    login: function() {
      this.$router.push("/login").catch(() => {});
      this.$store.commit("setFadeMenu", false);
    },
    logout: function() {
      if (this.$store.state.sToken !== "") {
        const config = {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
        };

        DB.delete(`${URI}/api/v1/${this.selectLanguage}/session`, config)
          .then((response) => {
            const self = this;

            localStorage.clear();
            this.$store.commit("setIsLogged", false);
            this.$store.commit("setFadeMenu", false);
            this.$store.commit("setToken", "");
            this.$store.commit("setFullName", "");
            this.$store.commit("setEnterpriseId", "");
            this.$store.commit("setUserId", "");

            setTimeout(function() {
              self.mixSuccess(response.data.message);
            }, 200);
            setTimeout(function() {
              self.$router
                .push({
                  path: "/login",
                })
                .catch(() => {});
            }, 400);
          })
          .catch((error) => {
            this.mixError(error.response.data.message, error.response.status);
          });
      }
    },
  },
  computed: {
    fadeMenu: function() {
      return this.$store.state.fadeMenu;
    },
    selectLanguage: function() {
      return this.$store.state.language;
    },
    isLogged: function() {
      return this.$store.state.isLogged;
    },
  },
  watch: {
    selectLanguage: function() {
      if (this.selectLanguage) {
        this.texts = FILE.menu[this.selectLanguage];
      }
    },
    fadeMenu: function() {
      if (this.fadeMenu) {
        if (this.$route.name == "Catalog" || this.$route.name == "Subcatalog" || this.$route.name == "Article") {
          this.activeCatalogGroup = true;
        } else {
          this.activeCatalogGroup = false;
        }

        if (
          this.$route.path == "/profile/tab/1" ||
          this.$route.path == "/profile/tab/2" ||
          this.$route.path == "/profile/tab/3" ||
          this.$route.path == "/profile/tab/4"
        ) {
          this.activeProfileGroup = true;
        } else {
          this.activeProfileGroup = false;
        }

        if (
          this.$route.path == "/order/tab/1" ||
          this.$route.path == "/order/tab/2" ||
          this.$route.path == "/order/tab/3"
        ) {
          this.activeOrderGroup = true;
        } else {
          this.activeOrderGroup = false;
        }

        if (this.$route.path == "/disputes/tab/1" || this.$route.path == "/disputes/tab/2") {
          this.activeDisputesGroup = true;
        } else {
          this.activeDisputesGroup = false;
        }
      }
    },
  },
};
</script>

<style>
.content-options-menu .mdi:before,
.content-options-menu .mdi-set {
  color: #ffffff !important;
}

.content-options-menu .v-list-group__items {
  margin-left: 45px !important;
}
</style>

<style scoped>
.active-menu {
  background: transparent linear-gradient(190deg, #ff8181 0%, #ffa77e 100%) 0% 0% no-repeat padding-box;
  width: 11px;
  height: 50px;
  position: absolute;
  left: -25px;
  border-top-right-radius: 11px;
  border-bottom-right-radius: 11px;
}

/* active menu catalogs */
.active-menu-1 {
  background: transparent linear-gradient(190deg, #ff8181 0%, #ffa77e 100%) 0% 0% no-repeat padding-box;
  width: 11px;
  height: 50px;
  position: absolute;
  left: -25px;
  border-top-right-radius: 11px;
  border-bottom-right-radius: 11px;
}

.active-menu-2 {
  background: transparent linear-gradient(190deg, #ff8181 0%, #ffa77e 100%) 0% 0% no-repeat padding-box;
  width: 11px;
  height: 50px;
  position: absolute;
  left: -25px;
  top: 115px;
  border-top-right-radius: 11px;
  border-bottom-right-radius: 11px;
}

.active-menu-3 {
  background: transparent linear-gradient(190deg, #ff8181 0%, #ffa77e 100%) 0% 0% no-repeat padding-box;
  width: 11px;
  height: 50px;
  position: absolute;
  left: -25px;
  top: 170px;
  border-top-right-radius: 11px;
  border-bottom-right-radius: 11px;
}

/* active menu profile */
.active-menu-4 {
  background: transparent linear-gradient(190deg, #ff8181 0%, #ffa77e 100%) 0% 0% no-repeat padding-box;
  width: 11px;
  height: 50px;
  position: absolute;
  left: -25px;
  border-top-right-radius: 11px;
  border-bottom-right-radius: 11px;
}

.active-menu-5 {
  background: transparent linear-gradient(190deg, #ff8181 0%, #ffa77e 100%) 0% 0% no-repeat padding-box;
  width: 11px;
  height: 50px;
  position: absolute;
  left: -25px;
  top: 110px;
  border-top-right-radius: 11px;
  border-bottom-right-radius: 11px;
}

.active-menu-6 {
  background: transparent linear-gradient(190deg, #ff8181 0%, #ffa77e 100%) 0% 0% no-repeat padding-box;
  width: 11px;
  height: 50px;
  position: absolute;
  left: -25px;
  top: 170px;
  border-top-right-radius: 11px;
  border-bottom-right-radius: 11px;
}

.active-menu-7 {
  background: transparent linear-gradient(190deg, #ff8181 0%, #ffa77e 100%) 0% 0% no-repeat padding-box;
  width: 11px;
  height: 50px;
  position: absolute;
  left: -25px;
  top: 225px;
  border-top-right-radius: 11px;
  border-bottom-right-radius: 11px;
}

.active-menu-8 {
  background: transparent linear-gradient(190deg, #ff8181 0%, #ffa77e 100%) 0% 0% no-repeat padding-box;
  width: 11px;
  height: 50px;
  position: absolute;
  left: -25px;
  top: 59px;
  border-top-right-radius: 11px;
  border-bottom-right-radius: 11px;
}

.active-menu-9 {
  background: transparent linear-gradient(190deg, #ff8181 0%, #ffa77e 100%) 0% 0% no-repeat padding-box;
  width: 11px;
  height: 50px;
  position: absolute;
  left: -25px;
  top: 115px;
  border-top-right-radius: 11px;
  border-bottom-right-radius: 11px;
}

.active-menu-10 {
  background: transparent linear-gradient(190deg, #ff8181 0%, #ffa77e 100%) 0% 0% no-repeat padding-box;
  width: 11px;
  height: 50px;
  position: absolute;
  left: -25px;
  top: 171px;
  border-top-right-radius: 11px;
  border-bottom-right-radius: 11px;
}

.active-menu-11 {
  background: transparent linear-gradient(190deg, #ff8181 0%, #ffa77e 100%) 0% 0% no-repeat padding-box;
  width: 11px;
  height: 50px;
  position: absolute;
  left: -25px;
  top: 55px;
  border-top-right-radius: 11px;
  border-bottom-right-radius: 11px;
}

.active-menu-12 {
  background: transparent linear-gradient(190deg, #ff8181 0%, #ffa77e 100%) 0% 0% no-repeat padding-box;
  width: 11px;
  height: 50px;
  position: absolute;
  left: -25px;
  top: 115px;
  border-top-right-radius: 11px;
  border-bottom-right-radius: 11px;
}

.button-session {
  background-color: transparent !important;
  height: 70px !important;
  width: 100% !important;
  border-radius: 0px;
  color: #ffffff !important;
  font-size: 16px;
  text-transform: initial;
  justify-content: flex-start;
  padding-left: 40px !important;
}

.content-options-menu {
  padding: 25px;
}

.content-options-menu .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled):hover {
  background-color: #17283b;
  /* margin-top: 10px; */
  cursor: pointer;
  border-radius: 13px;
}

.content-options-menu .v-icon.v-icon,
.content-options-menu .v-list-item__title,
.content-options-menu .mdi:before,
.content-options-menu .mdi-set,
.content-options-menu .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: #ffffff !important;
}

.content-options-menu .v-list-item--active {
  color: #ffffff !important;
  background: #283c4d 0% 0% no-repeat padding-box;
  border-radius: 13px;
}

.content-options-menu .v-list-item--active::before {
  border-radius: 13px;
}

.content-options-menu .v-list-item__icon {
  margin-right: 20px !important;
}

.container-options-menu,
.container-languages {
  border-bottom: 2px solid #283c4d;
}

.container-options-menu {
  overflow: auto;
}

.container-options-menu::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.container-options-menu {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.content-image {
  width: 100%;
  height: 60px;
  padding: 10px 110px;
  border-bottom: 2px solid #283c4d;
}

.content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-image img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

.button-close-menu {
  min-width: 39px !important;
  height: 39px !important;
  background-color: transparent !important;
  opacity: 1;
  border: 1px solid #ffffff;
  margin-left: 15px;
  margin-top: 20px;
  padding: 0px !important;
}

.container-menu-left {
  background: #151d27 0% 0% no-repeat padding-box;
  width: 365px;
  height: 100%;
  opacity: 1;
}

.container-menu-right {
  background-color: #000000;
  width: calc(100% - 320px);
  height: 100%;
  opacity: 0.9;
}

.content-fade {
  position: fixed;
  z-index: 5;
  width: 100%;
  height: 100vh;
  display: flex;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

@media (max-width: 425px) {
  .container-menu-left {
    width: 290px;
  }

  .container-menu-right {
    width: calc(100% - 260px);
  }

  .content-image {
    padding: 10px 60px;
  }
}
</style>
